// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import VinCheck from './components/VinCheck.vue';
import AdminPage from './components/AdminPage.vue';
import LoginPage from './components/LoginPage.vue'; // Страница логина
import MainPage from './components/MainPage.vue';   // Главная страница для ввода VIN-кода

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage, // Главная страница для ввода VIN-кода
  },
  {
    path: '/vin-check',
    name: 'VinCheck',
    component: VinCheck, // Страница для проверки VIN-кода
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage, // Страница для входа в админ-панель
  },
  {
    path: '/admin',
    name: 'AdminPage',
    component: AdminPage, // Страница для добавления новой информации
    meta: { requiresAuth: true }, // Эта страница требует авторизации
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Навигационный гвард для проверки авторизации
router.beforeEach((to, from, next) => {
  // Проверяем, требует ли маршрут авторизации
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isLoggedIn = localStorage.getItem('loggedIn');
    
    // Если не залогинен, перенаправляем на страницу логина
    if (!isLoggedIn) {
      next({ name: 'LoginPage' });
    } else {
      next(); // Продолжаем к запрашиваемому маршруту
    }
  } else {
    next(); // Если маршрут не требует авторизации, продолжаем
  }
});

export default router;
