<template>
  <div class="login-container">
    <h1>Вход в админ-панель</h1>
    <form @submit.prevent="login" class="login-form">
      <div class="form-group">
        <label for="username">Логин:</label>
        <input v-model="username" type="text" id="username" required />
      </div>
      <div class="form-group">
        <label for="password">Пароль:</label>
        <input v-model="password" type="password" id="password" required />
      </div>
      <button type="submit" class="login-button">Войти</button>
      <p v-if="error" class="error-message">{{ error }}</p>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      error: null,
    };
  },
  methods: {
    login() {
      if (this.username === 'admin' && this.password === 'M33b33a33') {
        localStorage.setItem('loggedIn', true);
        this.$router.push('/admin');
      } else {
        this.error = 'Неверный логин или пароль';
      }
    },
  },
};
</script>

<style scoped>
/* Центрирование контейнера */
.login-container {
  width: 400px;
  margin: 100px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  font-family: arial Regular, "Times New Roman";
}

/* Оформление формы */
.login-form {
  display: flex;
  flex-direction: column;
  font-family: arial Regular, "Times New Roman";
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #fff;
}

input:focus {
  border-color: #007bff;
  outline: none;
}

/* Кнопка входа */
.login-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #0056b3;
}

/* Сообщение об ошибке */
.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}
</style>
