<template>
  <div :class="{'fade-in': true, 'loaded': isLoaded}">
    <div class="vin-check-container">
      <div class="vin-check-cont2">
        <h2>Search</h2>
        <div class="input-container">
          <input v-model="vin" placeholder="Введите VIN-код" class="input-field" />
          <button @click="debouncedCheckVin" class="check-button">Check</button>

          <!-- Кнопка "Download all" после кнопки "Check" -->
          <button v-if="vinData && (vinData.photos.length || vinData.photos1.length || vinData.photos2.length)"
            @click="downloadPhotos" class="download-button">
            <img src="@/assets/img_dowload.png" alt="Download Icon" class="button-icon" />
            Download all
          </button>
        </div>
      </div>

      <!-- Контейнер для данных о VIN -->
      <div v-if="vinData" class="vin-info-container">
        <div class="vin-info">
          <div class="info-block">
            <img src="@/assets/Vincode.jpg" alt="Image" class="info-image">
            <div class="text-content">
              <p><strong>VIN</strong> {{ vinData.VIN }}</p>
            </div>
          </div>

          <div class="info-block">
            <img src="@/assets/model.jpg" alt="Image" class="info-image">
            <div class="text-content">
              <p><strong>Auto</strong> {{ vinData.Name }}</p>
            </div>
          </div>

          <div class="info-block">
            <img src="@/assets/Container.jpg" alt="Image" class="info-image">
            <div class="text-content">
              <p>
                <strong>Container</strong>
                <a :href="`https://www.maersk.com/tracking/${vinData.ContainerNumber}`" target="_blank">
                  {{ vinData.ContainerNumber }}
                </a>
              </p>
            </div>
          </div>

          <div class="info-block">
            <img src="@/assets/Port.jpg" alt="Image" class="info-image">
            <div class="text-content">
              <p><strong>Port of loading</strong> {{ vinData.OriginPort }}</p>
            </div>
          </div>
        </div>


        <!-- Блоки с фотографиями -->
        <div v-if="cachedPhotos.length > 0">
          <div class="photo-cont-main">
            <h2>PICK UP</h2>
          </div>
          <div class="photo-container">
            <div v-for="(photo, index) in cachedPhotos" :key="index" class="photo-item">
              <!-- Используем ленивую загрузку с vue-lazyload -->
              <img :src="photo" alt="Photo" class="photo" @click="openLightbox(photo, index, cachedPhotos)" />
            </div>
          </div>
        </div>

        <div v-if="cachedPhotos1.length > 0">
          <div class="photo-cont-main">
            <h2>PORT OF LOADING</h2>
          </div>
          <div class="photo-container">
            <div v-for="(photo, index) in cachedPhotos1" :key="index" class="photo-item">
              <img :src="photo" alt="Photo" class="photo" @click="openLightbox(photo, index, cachedPhotos1)" />
            </div>
          </div>
        </div>

        <div v-if="cachedPhotos2.length > 0">
          <div class="photo-cont-main">
            <h2>PORT OF DISCHARGE</h2>
          </div>
          <div class="photo-container">
            <div v-for="(photo, index) in cachedPhotos2" :key="index" class="photo-item">
              <img :src="photo" alt="Photo" class="photo" @click="openLightbox(photo, index, cachedPhotos2)" />
            </div>
          </div>
        </div>
      </div>




      <!-- Модальное окно для фотографий -->
      <vue-easy-lightbox
        :visible="lightboxVisible"
        :imgs="lightboxPhotos"
        :index="lightboxIndex"
        @hide="lightboxVisible = false"
      />

      <div v-if="addError" class="error-message">{{ addError }}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VueEasyLightbox from 'vue-easy-lightbox';
import VueLazyload from 'vue-lazyload'; // Подключаем VueLazyload
import _ from 'lodash'; // Для дебаунс-функции

export default {
  components: {
    VueEasyLightbox,
  },
  directives: {
    lazy: VueLazyload.directive, // Регистрация директивы для ленивой загрузки
  },
  data() {
    return {
      vin: '', // Значение VIN-кода
      vinData: null, // Данные по VIN-коду
      addError: null, // Ошибки
      isLoaded: false, // Флаг для отслеживания загрузки страницы
      lightboxVisible: false, // Флаг видимости модального окна
      lightboxPhotos: [], // Массив фотографий для модального окна
      lightboxIndex: 0, // Индекс текущей фотографии
      // Новые массивы для кэширования фотографий
      cachedPhotos: [],
      cachedPhotos1: [],
      cachedPhotos2: [],
    };
  },
  mounted() {
    // Плавное появление страницы после полной загрузки
    setTimeout(() => {
      this.isLoaded = true;
    }, 100); // Таймаут для плавного появления страницы

    if (this.$route.query.vin) {
      this.vin = this.$route.query.vin;
      this.debouncedCheckVin(); // Автоматически выполняем проверку, если VIN есть в URL
    }
  },
  created() {
    // Дебаунс функция для оптимизации запросов
    this.debouncedCheckVin = _.debounce(this.checkVin, 500);
  },
  methods: {
    getPhotoUrl(photoPath) {
      return photoPath.startsWith('http') ? photoPath : `https://carstracking.online:3001/${photoPath.replace(/\\/g, '/')}`;
    },

    async checkVin() {
      if (this.vin.length !== 17) {
        this.addError = 'Код неверный';
        this.vinData = null;
        return;
      }
      try {
        const response = await axios.get(`https://carstracking.online:3001/getVinInfo/${this.vin}`);
        this.vinData = response.data;
        this.addError = null;

        // Кэшируем фотографии
        this.cachedPhotos = this.vinData.photos.map((photo) => this.getPhotoUrl(photo));
        this.cachedPhotos1 = this.vinData.photos1.map((photo) => this.getPhotoUrl(photo));
        this.cachedPhotos2 = this.vinData.photos2.map((photo) => this.getPhotoUrl(photo));

        // Обновляем URL с новым VIN-кодом
        this.$router.push({ path: '/vin-check', query: { vin: this.vin } });      
     

      } catch (error) {
        this.addError = error.response && error.response.status === 404 ? 'VIN не найден' : 'Ошибка при получении данных';
        this.vinData = null;
      }
    },

    async downloadPhotos() {
      try {
        const response = await axios.get(`https://carstracking.online:3001/getVinPhotosArchive/${this.vin}`, {
          responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.vin}.zip`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.addError = 'Ошибка при загрузке архива';
      }
    },

    openLightbox(photo, index, photosArray) {
      this.lightboxPhotos = photosArray; // Передаем массив фотографий
      this.lightboxIndex = index; // Индекс текущей фотографии
      this.lightboxVisible = true; // Открываем модальное окно
    },
  },
};
</script>

<style scoped>

.fade-in {
  opacity: 0;
  transition: opacity 2s ease-in; /* Плавное изменение прозрачности */
}
.fade-in.loaded {
  opacity: 2;
}

/* Стили для страницы и модального окна */
.vin-check-container {
  max-width: auto;
  margin: auto;
  padding: 20px;
  background-color: #efefef;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: HELVETICA, wide , "Times New Roman";
}

.vin-check-container {
  max-width: auto;
  margin:  auto;
  padding: 20px;
  background-color: #efefef;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: HELVETICA, wide , "Times New Roman";
}

.vin-check-cont2 {
  background-color: #ffffff;
  width: auto;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  transition: transform 0.3s ease; /* Анимация трансформации */
}

.vin-check-cont2:hover {
  transform: scale(1.01); /* Увеличение на 5% при наведении */
}

.vin-info-container {
  margin-top: 20px;
  align-items: center;
}

h2, h3 {
  text-align: left;
  font-size: 1em;
  margin-bottom: 10px;
  padding: 5px;
}

.input-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-bottom: 10px;
  gap: 10px;
}

.input-field {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 300px;

}

.check-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.check-button:hover {
  background-color: #0056b3;
}

.vin-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Два столбца */
  gap: 20px; /* Отступы между блоками */
  padding: 0px;
  background-color: efefef;
  border-radius: 8px;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05);
}

.vin-info p {
  margin: 0; /* Убираем стандартные отступы для p */
}

.vin-info p strong {
  display: block; /* Заголовок на своей строке */
  margin-bottom: 5px; /* Отступ между заголовком и текстом */
}

@media (max-width: 768px) {
  .vin-info {
    grid-template-columns: 1fr; /* Один столбец для узких экранов */
  }
}


.info-block {
  display: flex; /* Горизонтальное расположение картинки и текста */
  align-items: center; /* Центрирование элементов по вертикали */
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  gap: 10px; /* Расстояние между картинкой и текстом */
  border: 0px solid #ddd;
  transition: transform 0.3s ease;
}

.info-image {
  width: 30px; /* Ширина изображения */
  height: 30px; /* Высота изображения */
  object-fit: cover; /* Сохраняем пропорции изображения */
  border-radius: 0%; /* Сделать изображение округлым */
}

.text-content {
  flex-grow: 1; /* Текст занимает оставшееся пространство */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Центрируем текст по вертикали */
}


.info-block:hover {
  transform: scale(1.02); /* Увеличение на 5% при наведении */
}

.download-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: HELVETICA, wide , "Times New Roman";
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #218838;
}

.button-icon {
  width: 25px;
  height: 16px;
  margin-right: 8px;
}

.photo-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 фотографии в ряд */
  gap: 20px; /* Отступы между фотографиями */
  margin-top: 20px;

}

.photo-cont-main {
  display: flex;
  width: auto;
  flex-wrap: wrap;
  gap: 10px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  margin-top:20px;
  transition: transform 0.3s ease; /* Анимация трансформации */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.photo-cont-main:hover {
  transform: scale(1.01); /* Увеличение на 5% при наведении */
}

.photo-item {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;

}

.photo {
  width: 100%;
  height: 300px;
  object-fit: cover; /* Сохраняем пропорции фотографии */
  border-radius: inherit;
  transition: transform 0.3s ease; /* Анимация при наведении */
}

.photo:hover {
  transform: scale(1.05); /* Увеличение на 5% при наведении */
}

/* Для экранов средних размеров */
@media (max-width: 1200px) {
  .photo-container {
    grid-template-columns: repeat(3, 1fr); /* 3 фотографии в ряд */
  }
}

/* Для маленьких экранов */
@media (max-width: 768px) {
  .photo-container {
    grid-template-columns: repeat(2, 1fr); /* 2 фотографии в ряд */
  }
}

/* Для самых маленьких экранов */
@media (max-width: 768px) {
  .photo-container {
    grid-template-columns: 1fr; /* Фотографии одна под другой */
  }
}

.error-message {
  color: red;
  font-size: 1.1em;
  text-align: center;
  margin-top: 20px;
}

</style>






















































                                                                                                                                                                                                                                                                        
