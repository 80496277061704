// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Импортируем наш router

import VueLazyload from 'vue-lazyload';

const app = createApp(App);

// Инициализация Vue Lazyload в Vue 3.x
app.use(VueLazyload, {
  preLoad: 1.3,
  error: '/assets/del.png', // Укажите путь к изображению, если фото не удается загрузить
  loading: '/assets/service.png', // Укажите путь к изображению для отображения во время загрузки
  attempt: 1,
});

app.use(router).mount('#app');
