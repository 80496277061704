<template>
  <transition name="fade">
    <div class="main-page-container" v-show="isMounted">
      <div class="form-container">
        <img src="@/assets/logo-big.png" alt="Car Image" class="form-image" />
        <input v-model="vin" placeholder="VIN" />
        <button @click="goToVinCheck">Check</button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      vin: '',
      isMounted: false, // Используем для управления отображением
    };
  },
  methods: {
    goToVinCheck() {
      if (this.vin.length === 17) {
        this.$router.push({ name: 'VinCheck', query: { vin: this.vin } });
      } else {
        alert('Введите правильный 17-значный VIN-код');
      }
    },
  },
  mounted() {
    // Плавное включение отображения страницы после монтирования
    setTimeout(() => {
      this.isMounted = true;
    }, 50); // Задержка, чтобы анимация отработала плавно
  },
};
</script>

<style scoped>
/* Анимация для плавного появления */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Контейнер для всей страницы */
.main-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url("@/assets/fon.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: Calibri Light, "Times New Roman";
  position: fixed;
  top: 0;
  left: 0;
}

/* Контейнер для формы */
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.form-container:hover {
  transform: scale(1.03);
}

.form-image {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 300px;
}

button {
  padding: 10px 40px;
  background-color: #37629f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #003b72;
}
</style>
