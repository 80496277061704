<template>
  <div class="admin-container">
    <h1></h1>
    
    <form @submit.prevent="addVinInfo" class="vin-form">
      <!-- Кнопка выхода -->
      <button @click="logout" class="logout-button">Выйти</button>
    
    <!-- Форма добавления новых данных -->
      <div class="form-group">
        <input v-model="newVin" placeholder="Введите новый VIN-код" required />
      </div>
      <div class="form-group">
        <input v-model="newName" placeholder="Введите имя" required />
      </div>
      <div class="form-group">
        <input v-model="newContainerNumber" placeholder="Введите номер контейнера" />
      </div>
      <div class="form-group">
        <input v-model="newOriginPort" placeholder="Введите порт отправления" required />
      </div>

      <!-- Фотографии для каждого блока -->
      <div class="form-group">
        <h4>Pick up</h4>
        <input type="file" multiple ref="fileInputPickUp" @change="handlePhotoUploadPickUp" />
      </div>

      <div class="form-group">
        <h4>Port of loading</h4>
        <input type="file" multiple ref="fileInputLoading" @change="handlePhotoUploadLoading" />
      </div>

      <div class="form-group">
        <h4>Port of discharge</h4>
        <input type="file" multiple ref="fileInputDischarge" @change="handlePhotoUploadDischarge" />
      </div>

      <button type="submit" class="submit-button">Добавить</button>

      <p v-if="addError" class="error-message">{{ addError }}</p>
      <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
    </form>

    <!-- Поле поиска -->
    <div class="form-group search">
      <input v-model="searchQuery" placeholder="Поиск по VIN-коду" />
    </div>

    <!-- Таблица данных -->
    <table class="data-table">
      <thead>
        <tr>
          <th>VIN</th>
          <th>Name</th>
          <th>Container</th>
          <th>Port</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="car in filteredCars" :key="car.id">
          <td>{{ car.VIN }}</td>
          <td>{{ car.Name }}</td>
          <td>{{ car.ContainerNumber }}</td>
          <td>{{ car.OriginPort }}</td>
          <td>
            <!-- Кнопка редактировать с изображением -->
            <button class="edit-button" @click="editCar(car)">
              <img src="@/assets/service.png" alt="Редактировать" />

            </button>
            <!-- Кнопка удалить с изображением -->
            <button class="delete-button" @click="deleteCar(car.id)">
              <img src="@/assets/del.png" alt="Удалить" />

            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Модальное окно для редактирования -->
    <div v-if="isEditModalOpen" class="modal-overlay">
      <div class="modal-content">
        <span @click="closeEditModal" class="close">&times;</span>
        <h2>Редактировать VIN-код</h2>

        <form @submit.prevent="saveCarEdit">
          <!-- Поле VIN-код (не редактируемое) -->
          <div class="form-group1">
            <input v-model="editVin" placeholder="VIN-код" required />
          </div>

          <!-- Редактируемые поля -->
          <div class="form-group1">
            <input v-model="editName" placeholder="Имя" required />
          </div>
          <div class="form-group1">
            <input v-model="editContainerNumber" placeholder="Номер контейнера" />
          </div>
          <div class="form-group1">
            <input v-model="editOriginPort" placeholder="Порт отправления" required />
          </div>
          <button type="submit" class="submit-button">Сохранить</button>
          

          <!-- Фотографии в модальном окне -->
          <div class="form-group1">
            <h3>Pick up</h3>
            <input type="file" multiple ref="editFileInputPickUp" @change="handleEditPhotoUploadPickUp" />
            <ul>
              <li v-for="(photo, index) in editPhotos" :key="index">
                <img :src="getPhotoUrl(photo)" alt="Pick up" />
              </li>
            </ul>
          </div>

          <div class="form-group1">
            <h3>Port of loading</h3>
            <input type="file" multiple ref="editFileInputLoading" @change="handleEditPhotoUploadLoading" />
            <ul>
              <li v-for="(photo, index) in editPhotos1" :key="index">
                <img :src="getPhotoUrl(photo)" alt="Port of Loading" />
              </li>
            </ul>
          </div>

          <div class="form-group1">
            <h3>Port of discharge</h3>
            <input type="file" multiple ref="editFileInputDischarge" @change="handleEditPhotoUploadDischarge" />
            <ul>
              <li v-for="(photo, index) in editPhotos2" :key="index">
                <img :src="getPhotoUrl(photo)" alt="Port of Discharge" />
              </li>
            </ul>
          </div>

          
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      newVin: '',
      newName: '',
      newContainerNumber: '',
      newOriginPort: '',
      photos: [],
      photos1: [],
      photos2: [],
      addError: null,
      successMessage: null,
      cars: [],
      searchQuery: '',
      isEditModalOpen: false,
      editVin: '',
      editName: '',
      editContainerNumber: '',
      editOriginPort: '',
      editId: null,
      editPhotos: [],
      editPhotos1: [],
      editPhotos2: [],
    };
  },
  computed: {
    filteredCars() {
      if (this.searchQuery) {
        return this.cars.filter((car) => car.VIN.includes(this.searchQuery));
      }
      return this.cars;
    },
  },
  methods: {
    async addVinInfo() {
      const formData = new FormData();
      formData.append('VIN', this.newVin);
      formData.append('Name', this.newName);
      formData.append('ContainerNumber', this.newContainerNumber);
      formData.append('OriginPort', this.newOriginPort);
      this.photos.forEach((photo) => formData.append('photos', photo));
      this.photos1.forEach((photo) => formData.append('photos1', photo));
      this.photos2.forEach((photo) => formData.append('photos2', photo));

      try {
        await axios.post('https://carstracking.online:3001/addVinInfo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.successMessage = 'Данные успешно добавлены';
        this.addError = null;
        this.resetForm();
        this.fetchCars(); // Обновление данных
      } catch (error) {
        this.addError = 'Ошибка при добавлении данных';
      }
    },
    async fetchCars() {
      try {
        const response = await axios.get('https://carstracking.online:3001/getAllVinInfo');
        this.cars = response.data.map((car) => ({
          ...car,
          Photo: car.Photo ? car.Photo.split(',') : [],  // Преобразование строки в массив
          Photo1: car.Photo1 ? car.Photo1.split(',') : [],
          Photo2: car.Photo2 ? car.Photo2.split(',') : [],
        }));
      } catch (error) {
        console.error('Ошибка при загрузке данных', error);
      }
    },
    editCar(car) {
      this.isEditModalOpen = true;
      this.editId = car.id;
      this.editVin = car.VIN;
      this.editName = car.Name;
      this.editContainerNumber = car.ContainerNumber;
      this.editOriginPort = car.OriginPort;
      this.editPhotos = Array.isArray(car.Photo) ? car.Photo : [];  // Проверка, массив ли это
      this.editPhotos1 = Array.isArray(car.Photo1) ? car.Photo1 : [];
      this.editPhotos2 = Array.isArray(car.Photo2) ? car.Photo2 : [];
    },
    async saveCarEdit() {
      const formData = new FormData();
      formData.append('VIN', this.editVin); // добавил ВИН
      formData.append('Name', this.editName);
      formData.append('ContainerNumber', this.editContainerNumber);
      formData.append('OriginPort', this.editOriginPort);

      // Проверка, что это массивы, перед использованием forEach
      (Array.isArray(this.editPhotos) ? this.editPhotos : []).forEach((photo) => formData.append('photos', photo));
      (Array.isArray(this.editPhotos1) ? this.editPhotos1 : []).forEach((photo) => formData.append('photos1', photo));
      (Array.isArray(this.editPhotos2) ? this.editPhotos2 : []).forEach((photo) => formData.append('photos2', photo));

      try {
        await axios.put(`https://carstracking.online:3001/updateVinInfo/${this.editId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.closeEditModal();
        this.fetchCars();
      } catch (error) {
        console.error('Ошибка при сохранении изменений', error);
      }
    },
    handlePhotoUploadPickUp(event) {
      this.photos = Array.from(event.target.files);
    },
    handlePhotoUploadLoading(event) {
      this.photos1 = Array.from(event.target.files);
    },
    handlePhotoUploadDischarge(event) {
      this.photos2 = Array.from(event.target.files);
    },
    handleEditPhotoUploadPickUp(event) {
      this.editPhotos = Array.from(event.target.files);
    },
    handleEditPhotoUploadLoading(event) {
      this.editPhotos1 = Array.from(event.target.files);
    },
    handleEditPhotoUploadDischarge(event) {
      this.editPhotos2 = Array.from(event.target.files);
    },
    closeEditModal() {
      this.isEditModalOpen = false;
      this.resetEditForm();
    },
    resetForm() {
      this.newVin = '';
      this.newName = '';
      this.newContainerNumber = '';
      this.newOriginPort = '';
      this.photos = [];
      this.photos1 = [];
      this.photos2 = [];
    },
    resetEditForm() {
      this.editVin = '';
      this.editName = '';
      this.editContainerNumber = '';
      this.editOriginPort = '';
      this.editPhotos = [];
      this.editPhotos1 = [];
      this.editPhotos2 = [];
    },
    async deleteCar(id) {
      try {
        await axios.delete(`https://carstracking.online:3001/deleteVinInfo/${id}`);
        this.fetchCars(); // Обновление данных
      } catch (error) {
        console.error('Ошибка при удалении данных', error);
      }
    },
    logout() {
      localStorage.removeItem('loggedIn');
      this.$router.push('/login');
    },
    // Добавляем функцию для получения корректного пути к фотографиям
    getPhotoUrl(photo) {
      return `https://carstracking.online:3001/${photo}`;
    },
  },
  mounted() {
    this.fetchCars();
  },
};
</script>

<style scoped>
/* Общий стиль страницы */
.admin-container {
  font-family: HELVETICA, wide, "Times New Roman";
  padding: 20px;
}

h1 {
  text-align: center;
}

/* Контейнеры с закруглениями */
.form-group input,
.data-table,
.modal-content,
input[type="file"],
textarea {
  border-radius: 0px;
}

.form-group {
  padding: 0px;
  gap: 0px;
  width: 600px; 
}

.form-group1 {
  margin-bottom: 10px;
  border-radius: 6px;
}

input {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  color: #333;
  transition: border-color 0.3s ease;
}

/* Стиль для поля VIN-кода (не редактируемое) */
input[disabled] {
  background-color: #f7f7f7;
  color: #888;
  cursor: not-allowed;
}

/* При фокусе поля */
input:focus {
  border-color: #007bff; /* Цвет при фокусе */
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}



/* Шрифт для всей страницы */
* {
  font-family: HELVETICA, wide, "Times New Roman";
}

/* Основные стили для формы */
.vin-form {
  display: flex;
  width: 500px; 
  flex-direction: column;
  gap: 4px;
  background-color: white;
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.0);
}

.vin-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: auto;
}

/* Кнопки */
button {
  background-color: #f2f2f2;
  color: white;
  padding: 10px 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: auto;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

/* Таблица */
.data-table {
  width: auto;
  table-layout: auto;
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 14px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.data-table th, .data-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  white-space: nowrap;
}

.data-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Поле поиска */
.search input {
  padding: 10px;
  gap: 10px;
  top: 10px;
  margin-top: 10px;
  width: auto;
  border-radius: 8px;
  border: 1px solid #ccc;
}

/* Модальное окно */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 50%;
  max-width: 800px;
  max-height: 80vh; /* Ограничение по высоте */
  overflow-y: auto; /* Прокрутка, если контент больше окна */
  position: relative;
  z-index: 10000; /* Поверх затемнения */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Кнопка закрытия */
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  color: #333;
}

ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 10px; /* Разрыв между фотографиями */
  flex-wrap: wrap;
}


li {
  display: inline-block;
}

img {
  height: 100px;
  width: 130px; /* Ширина авто, высота фиксированная */
  border-radius: 4px;
  border: 0px solid #ddd;
}


button img {
  width: 16px;
  height: 16px;
  margin-right: 0px; /* Отступ между иконкой и текстом */
}

.edit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 8px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px; /* Добавляет расстояние между кнопками */
}

.edit-button:hover {
  background-color: #0056b3;
}

.edit-button img {
  filter: invert(0%); /* Чтобы иконка гармонировала с кнопкой */
}

.delete-button {
  background-color: #ff5757;
  color: white;
  border: none;
  padding: 6px 8px;
  border-radius: 5px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #df2f2f;
}

.delete-button img {
  filter: invert(0%);
}


.submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 10px;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  width: 100px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.logout-button{
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%; /* Делаем ширину кнопки равной ширине контейнера */
  max-width: 100px; /* Устанавливаем максимальную ширину, как у поля VIN */
  display: block; /* Делаем кнопку блочным элементом */
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}






</style>
